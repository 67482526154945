<template>
  <div class="footer mt-2">
    <div class="row container">
      <div class="col-lg-2 right-arrow-border steps">
        <p class="p-selected">
          <i v-if="isFileUploaded" class="fas fa-check"></i>
          <span v-else>1</span>
          <small><strong>Select File</strong></small></p>
      </div>
      <div class="col-lg-3 right-arrow-border steps">
        <p :class="step === steps.SELECT_FILE ? 'p-non-selected' : 'p-selected'">
          <i v-if="step===steps.REVIEW" class="fas fa-check"></i>
          <span v-else>2</span>
          <small><strong>Map Attributes</strong></small></p>
      </div>
      <div class="col-lg-3 steps">
        <p :class="`${step !== steps.REVIEW ? 'p-non-selected' : 'p-selected'} ml-3`">
          <span>3</span>
          <small><strong>Review & Imports</strong></small></p>
      </div>
      <div class="col-lg-4 steps">
        <div class="text-left">
          <button
            v-if="showNext"
            :disabled="disableNext"
            @click="next()"
            class="ml-1 btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--left btn-bold">
            <span>Next</span>
          </button>
          <button v-if="showPrevious" @click="previous()" type="button"
                  class="btn---cta light-blue btn-round  btn-bold"><span>Previous</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {csvExportSteps} from '@/common/constants'

export default ({
  name: 'bulk-import-progress',
  props: {
    isFileUploaded: Boolean,
    step: String,
    disableNext: {
      type: Boolean,
      default: false
    },
    showPrevious: {
      type: Boolean,
      default: false
    },
    showNext: {
      type: Boolean,
      default: true
    },
    totalRecordsInCsv: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      steps: csvExportSteps,
    }
  },
  methods: {
    next () {
      if (this.step === this.steps.MAP_ATTRIBUTES && this.totalRecordsInCsv > 100) {
        this.alertMessage(`Your records are more than 100 so it should be run background process`, 'error')
        return
      }
      this.$emit('next')
    },
    previous () {
      this.$emit('previous')
    }
  }
})
</script>
